import React, { Fragment } from 'react'
import styles from './PageNavigation.module.scss'
import { Box, Flex } from '@components/UI/Grid/Grid'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import { stringify } from '@lib/helpers'
// import DynamicComponent from '@components/DynamicComponent/DynamicComponent'
// import { CtaButtonStoryblok } from 'types/storyblok-components'

const PageNavigation = ({
   links,
   url,
}: // button,
{
   links: string[]
   url: string
   // button?: CtaButtonStoryblok | undefined | null
}) => (
   <div className={styles.wrapper}>
      <div className={styles.inner}>
         {links && links.length > 0 && (
            <Flex as="nav" className={styles.nav}>
               {links.map((link: any) => {
                  if (typeof link === 'string') {
                     return (
                        <Box
                           marginRight={[1, null, null, 2]}
                           key={stringify(link)}
                           className={styles.navItem}
                        >
                           <LinkComponent href={`${url}#${stringify(link)}`}>
                              {link}
                           </LinkComponent>
                        </Box>
                     )
                  }
                  if (
                     link &&
                     link.component &&
                     link.component === 'chart_and_holdings'
                  ) {
                     const { id_for_chart, id_for_holdings, label } = link

                     return (
                        <Fragment key="chart-frag">
                           <Box
                              key={stringify(
                                 id_for_chart ? id_for_chart : label,
                              )}
                              marginRight={[1, null, null, 2]}
                              className={styles.navItem}
                           >
                              <LinkComponent
                                 href={`${url}#${stringify(
                                    id_for_chart ? id_for_chart : label,
                                 )}`}
                              >
                                 {id_for_chart ? id_for_chart : label}
                              </LinkComponent>
                           </Box>
                           {id_for_holdings && (
                              <Box
                                 key={stringify(id_for_holdings)}
                                 marginRight={[1, null, null, 2]}
                                 className={styles.navItem}
                              >
                                 <LinkComponent
                                    href={`${url}#${stringify(
                                       id_for_holdings,
                                    )}`}
                                 >
                                    {id_for_holdings}
                                 </LinkComponent>
                              </Box>
                           )}
                        </Fragment>
                     )
                  }
                  if (
                     link &&
                     link.component &&
                     link.component === 'information_and_documents'
                  ) {
                     const { information_label, documents_label } = link

                     return (
                        <Fragment key="info-frag">
                           {information_label && (
                              <Box
                                 key={stringify(information_label)}
                                 marginRight={[1, null, null, 2]}
                                 className={styles.navItem}
                              >
                                 <LinkComponent
                                    href={`${url}#${stringify(
                                       information_label,
                                    )}`}
                                 >
                                    {information_label}
                                 </LinkComponent>
                              </Box>
                           )}
                           {documents_label && (
                              <Box
                                 key={stringify(documents_label)}
                                 marginRight={[1, null, null, 2]}
                                 className={styles.navItem}
                              >
                                 <LinkComponent
                                    href={`${url}#${stringify(
                                       documents_label,
                                    )}`}
                                 >
                                    {documents_label}
                                 </LinkComponent>
                              </Box>
                           )}
                        </Fragment>
                     )
                  }
               })}
            </Flex>
         )}
      </div>
   </div>
)

export default PageNavigation
