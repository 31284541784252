import React, { Fragment } from 'react'
import { ContentProps } from './types'
import styles from './InformationAndDocuments.module.scss'
import { Container, Box, Flex } from '@components/UI/Grid/Grid'
import Markdown from '@components/UI/Markdown/Markdown'
import Label from '@components/UI/Label/Label'
import { stringify } from '@lib/helpers'
import LinkComponent from '@components/UI/LinkComponent/LinkComponent'
import Image from '@components/UI/Image/Image'

import Documents from '@components/Documents/Documents'

const InformationAndDocuments: React.FC<ContentProps> = ({
   blok,
   documents,
}) => {
   const {
      title,
      text,
      information_label,
      documents_label,
      hide_information,
      bottom_info,
      contact,
      fund,
      file_types,
   } = blok

   const sectionID = information_label ? stringify(information_label) : ''

   return (
      <Box
         paddingY={[10, null, null, 20]}
         as="section"
         width={1}
         className={styles.wrapper}
      >
         <div id={sectionID} className="anchor" />
         {!hide_information && (
            <Container>
               <Flex
                  alignItems="flex-start"
                  flexDirection="column"
                  className={styles.content}
               >
                  {information_label && (
                     <Box marginBottom={[4, null, null, 6]}>
                        <Label title={information_label} />
                     </Box>
                  )}
                  {title && (
                     <Box
                        className={styles.title}
                        as="h3"
                        marginBottom={[4, null, null, 6]}
                     >
                        {title}
                     </Box>
                  )}
               </Flex>
               <div className={styles.topGrid}>
                  <Flex className={styles.textSection} flexDirection={'column'}>
                     {text && (
                        <Box>
                           <Markdown className={styles.text}>{text}</Markdown>
                        </Box>
                     )}
                     {bottom_info && (
                        <Box marginTop="auto" className={styles.bottomInfo}>
                           {bottom_info}
                        </Box>
                     )}
                  </Flex>
                  {contact && contact.length > 0 && (
                     <Flex className={styles.contacts}>
                        {contact.map((blok: any) => {
                           const { title, phone, email, name, image } = blok

                           return (
                              <Box
                                 width="100%"
                                 key={blok._uid}
                                 className={styles.contact}
                              >
                                 <Box
                                    className={styles.imageWrapper}
                                    marginBottom={['12px', null, null, '12px']}
                                 >
                                    {image && image.filename && (
                                       <Image
                                          alt={name}
                                          offScreen
                                          src={image.filename}
                                       />
                                    )}
                                 </Box>
                                 {name && (
                                    <Box
                                       className={styles.contactName}
                                       marginBottom={['2px', null, null, '2px']}
                                    >
                                       {name}
                                    </Box>
                                 )}
                                 {title && (
                                    <Box className={styles.contactInfo}>
                                       {title}
                                    </Box>
                                 )}
                                 {phone && (
                                    <Box className={styles.contactInfo}>
                                       {phone}
                                    </Box>
                                 )}
                                 {email && (
                                    <Box className={styles.contactInfo}>
                                       <LinkComponent
                                          href={`mailto:${email}`}
                                       >{`${email}`}</LinkComponent>
                                    </Box>
                                 )}
                              </Box>
                           )
                        })}
                     </Flex>
                  )}
               </div>
            </Container>
         )}

         <Documents
            blok={{ files: documents }}
            fileTypes={file_types}
            documentsLabel={documents_label}
            fund={fund}
         />
      </Box>
   )
}

export default InformationAndDocuments
