import React from 'react'
import { ContentProps } from './types'
import styles from './Content.module.scss'
import { Container, Box, Flex } from '@components/UI/Grid/Grid'
import Markdown from '@components/UI/Markdown/Markdown'
import Label from '@components/UI/Label/Label'
import { getThemeColor, stringify } from '@lib/helpers'

const Content: React.FC<ContentProps> = ({ blok, isNews, isSeoPage }) => {
   const { title, text, label, Background } = blok

   return (
      <Box
         as="section"
         width={1}
         className={[
            styles.wrapper,
            `content-module-${Background}`,
            isSeoPage ? styles.seoPage : '',
         ].join(' ')}
         bg={getThemeColor(Background).background}
         color={getThemeColor(Background).color}
      >
         <div id={label ? stringify(label) : ''} className="anchor" />
         <Box className="content-inner" paddingY={[10, null, null, 20]}>
            <Container>
               <Flex justifyContent="center">
                  <Flex
                     flexDirection={'column'}
                     width={1}
                     maxWidth={isNews ? '1040px' : ''}
                  >
                     {label && (
                        <Flex justifyContent="center">
                           <Box width={[1, null, null, 1]}>
                              <Flex alignItems={'flex-start'}>
                                 <Box marginBottom={[5, null, null, 6]}>
                                    {label && <Label title={label} />}
                                 </Box>
                              </Flex>
                           </Box>
                        </Flex>
                     )}
                     <Flex justifyContent="center">
                        <Box width={[1, null, null, 1]}>
                           {title && (
                              <Box
                                 className={styles.title}
                                 marginBottom={[4, null, null, 4]}
                              >
                                 <Markdown>{title}</Markdown>
                              </Box>
                           )}
                           {text && (
                              <Box className={styles.text}>
                                 <Markdown>{text}</Markdown>
                              </Box>
                           )}
                        </Box>
                     </Flex>
                  </Flex>
               </Flex>
            </Container>
         </Box>
      </Box>
   )
}

export default Content
